import React, { useState } from 'react';
import { MdClear } from "react-icons/md"; // Import the close icon from react-icons

const SearchTool = () => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [googleResults, setGoogleResults] = useState([]);
    const [youtubeResults, setYoutubeResults] = useState([]);

    const handleSearch = () => {
        const query = encodeURIComponent(`${title} ${description}`).replace(/%20/g, '+');
        if (query === '') {
            alert('Please enter project title and description.');
            return;
        }

        // Clear previous search results
        setGoogleResults([]);
        setYoutubeResults([]);

        // Perform Google search
        fetch(`https://www.googleapis.com/customsearch/v1?key=AIzaSyCA6VEA0uisSnpsYU81rEZ07lLEbytxI_o&cx=d1d61ed55479845f5&q&q=${query}&num=5`)
            .then(response => response.json())
            .then(data => {
                setGoogleResults(data.items);
            })
            .catch(error => console.error('Error fetching Google search data:', error));

        // Perform YouTube search
        fetch(`https://www.googleapis.com/youtube/v3/search?key=AIzaSyCA6VEA0uisSnpsYU81rEZ07lLEbytxI_o&q=${query}&part=snippet&type=video&maxResults=5`)
            .then(response => response.json())
            .then(data => {
                setYoutubeResults(data.items);
            })
            .catch(error => console.error('Error fetching YouTube data:', error));
    };

    const handleClear = () => {
        setTitle('');
        setDescription('');
    };

    return (
        <div className="container">
            <div className="row">
                <div className="left-section col-sm-12 col-lg-6 col-md-6">
                    <h2>Project Verification Tool</h2>
                    <div className="search-bar">
                        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Enter project title..." style={{ fontSize: '14px' }}  />
                        {title && <MdClear className="clear-icon" onClick={() => setTitle('')} />}
                    </div>
                    <div className="search-bar">
                        <input type="text" value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Enter project description..." style={{ fontSize: '14px' }}  />
                        {description && <MdClear className="clear-icon" onClick={() => setDescription('')} />}
                    </div>
                    <button onClick={handleSearch}>Search</button>
                </div>
                <div className="right-section col-sm-12 col-lg-6 col-md-6">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                            <h4>Google Results</h4> 
                                <div className="search-section">
                                    <div id="googleResults" className="search-results">
                                        {googleResults.map((item, index) => (
                                            <div key={index}>
                                                <a href={item.link} target="_blank">
                                                    <h3>{item.title}</h3>
                                                    <p>{item.snippet}</p>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            <h4>Youtube Results</h4> 
                                <div className="search-section">
                                    <div id="youtubeResults" className="search-results">
                                        {youtubeResults.map((item, index) => (
                                            <div key={index}>
                                                <a href={`https://www.youtube.com/watch?v=${item.id.videoId}`} target="_blank">
                                                    <img src={item.snippet.thumbnails.default.url} alt={item.snippet.title} />
                                                    <p>{item.snippet.title}</p>
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>
    );
};

export default SearchTool;


